import React from 'react'
import styled from 'styled-components'
import { colors, vars } from './theme'

import DropdownArrow from './DropdownArrow'

const Wrapper = styled.button`
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 0.75rem;
  text-decoration: none;
  font-weight: 700;
  color: ${colors.grey};
  letter-spacing: 0.5px;
  position: relative;
  transition: all 0.125s ease-out;

  @media (min-width: ${vars.screenSmall}) {
    padding: 0.5rem 0.75rem;
    max-width: none;
    font-size: 0.875rem;
  }

  @media (hover) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 2px;
      bottom: 3px;
      transition: width 0.25s ease;
      background: ${colors.primary};
    }

    &:before {
      left: 50%;
    }
    &:after {
      right: 50%;
    }
  }

  &:hover,
  &:focus {
    color: ${colors.primary};
    background: ${colors.white};

    .dropdown-arrow {
      transform: rotateX(50deg);
    }

    @media (hover) {
      &:before,
      &:after {
        width: 45%;
      }
    }
  }

  &:visited {
    color: ${colors.grey};
  }

  &:active,
  &.active {
    color: ${colors.primaryDark};

    .dropdown-arrow {
      transform: rotateX(180deg);
    }
  }
`

const DropdownButton = ({ className, title, active }) => (
  <Wrapper
    aria-haspopup="true"
    type="button"
    aria-expanded={active}
    className={className}>
    {title}
    <DropdownArrow className="dropdown-arrow" active={active} />
  </Wrapper>
)

export default DropdownButton
