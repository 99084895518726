import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { colors, vars } from './theme'
import PageWrapper from './PageWrapper'

import footerLogo from '../images/svgs/footer-logo.svg'
import { Facebook, Instagram, LinkedIn, Twitter } from './Icons'

const SocialLinks = styled(({ className }) => (
  <div className={className}>
    <a
      href="https://www.facebook.com/FemaleFunders"
      rel="noopener noreferrer"
      target="_blank">
      <Facebook />
    </a>
    <a
      href="https://twitter.com/femalefunders"
      rel="noopener noreferrer"
      target="_blank">
      <Twitter />
    </a>
    <a
      href="https://www.linkedin.com/company/female-funders"
      rel="noopener noreferrer"
      target="_blank">
      <LinkedIn />
    </a>
    <a
      href="https://www.instagram.com/femalefunders"
      rel="noopener noreferrer"
      target="_blank">
      <Instagram />
    </a>
  </div>
))`
  margin-top: 1rem;

  @media screen and (min-width: ${vars.screenSmall}) {
    text-align: right;
  }

  a {
    opacity: 0.8;
    margin-left: 0.75rem;

    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

const Colophon = styled(({ className }) => (
  <div className={className}>
    <div>&copy; 2018 Highline BETA. All Rights Reserved.</div>
    <div>
      <Link to="/privacy-policy">Privacy Policy</Link> and{' '}
      <Link to="/terms-of-service">Terms of Service</Link>
    </div>
    <br />
    <div>
      Powered by{' '}
      <a
        href="https://highlinebeta.com"
        rel="noopener noreferrer"
        target="_blank">
        Highline BETA
      </a>
    </div>
  </div>
))`
  order: 2;
  font-size: 12px;

  @media screen and (min-width: ${vars.screenSmall}) {
    order: 1;
  }
`

const SiteMapLink = styled(Link)`
  display: inline-block;
  color: ${colors.primaryText};
  text-decoration: none;
  margin: 0 0.75rem 0 0;

  &:visited {
    color: ${colors.primaryText};
  }

  &:hover,
  &:focus {
    color: ${colors.primaryDark};
    text-decoration: underline;
  }

  @media screen and (min-width: ${vars.screenSmall}) {
    margin: 0 0 0 0.75rem;
  }
`

const Wrapper = styled.footer`
  width: 100vw;
  height: ${vars.footerHeight};
  background-color: ${colors.white};
  border-top: 8px solid ${colors.primary};
  padding: 1rem 0;
  position: relative;

  @media screen and (min-width: ${vars.screenSmall}) {
    height: ${vars.footerHeightWeb};
  }
`

const FooterBody = styled.div`
  display: grid;
  grid-gap: 2rem;
  text-align: center;

  @media screen and (min-width: ${vars.screenSmall}) {
    grid-gap: 1rem;
    text-align: left;
    grid-template-columns: repeat(2, 1fr);
  }
`
const FooterTagline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 2rem;

  h4 {
    display: inline-block;
    margin: 0;
  }
`

const FooterLogo = styled.img`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
`
const SiteMap = styled.div`
  margin: 0 0 0.375rem;

  @media screen and (min-width: ${vars.screenSmall}) {
    text-align: right;
  }
`

const SiteLinks = styled(({ className }) => (
  <div className={className}>
    <SiteMap>
      <SiteMapLink to="/angel-academy/what-is-angel-academy">
        Angel Academy
      </SiteMapLink>
      <SiteMapLink to="/about">Mission</SiteMapLink>
      <SiteMapLink to="/team">Team</SiteMapLink>
      <SiteMapLink to="/contact">Contact</SiteMapLink>
      <SiteMapLink to="/resources">Resources</SiteMapLink>
    </SiteMap>

    <SiteMap>
      <SiteMapLink to="/newsletter-signup">Newsletter Signup</SiteMapLink>
    </SiteMap>

    <SocialLinks />
  </div>
))`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  order: 1;
  font-size: 14px;

  @media screen and (min-width: ${vars.screenSmall}) {
    order: 2;
  }
`

const Footer = () => (
  <Wrapper>
    <PageWrapper>
      <FooterTagline>
        <FooterLogo src={footerLogo} alt="" />
        <h4>The future face of funding is you.</h4>
      </FooterTagline>

      <FooterBody>
        <Colophon />
        <SiteLinks />
      </FooterBody>
    </PageWrapper>
  </Wrapper>
)

export default Footer
