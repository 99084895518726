import logo from '../images/logos/ff-logo.svg'
import styled from 'styled-components'

export default styled.img.attrs({
  src: logo,
  alt: 'Female Funders',
})`
  display: inline-block;
  width: auto;
  height: 100%;
  max-height: 2.625rem;
  border-radius: 0;
`
