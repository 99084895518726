import React from 'react'
import styled from 'styled-components'

import chevronDown from '../images/svgs/chevron-down.svg'

const Image = styled.img`
  height: 0.75rem;
  width: 0.75rem;
  flex: 0 0 0.75rem;
  margin-left: 0.375rem;
  transition: transform 0.25s ease-out;
`

const DropdownArrow = ({ ...props }) => (
  <Image src={chevronDown} alt="" {...props} />
)

export default DropdownArrow
