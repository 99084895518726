import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, vars } from '../components/theme'

import Logo from './Logo'
import DropdownButton from './DropdownButton'

const Container = styled.div`
  z-index: 9000;
  width: 100%;
  height: ${vars.navHeight};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1rem;
  background: ${({ active }) => (active ? colors.primaryLight : '#fff')};
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: ${({ active }) =>
    active ? 'none' : '0 1px 3px rgba(0, 0, 0, 0.07)'};

  @media (min-width: ${vars.screenSmall}) {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
  }
`

const LogoContainer = styled(Link)`
  height: ${vars.navHeight};
  padding: 0.25rem 0;
  max-width: 8rem;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (min-width: ${vars.screenSmall}) {
    max-width: none;
  }
`

const MenuContainer = styled.header`
  z-index: 9001;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  height: ${vars.navHeight};
`

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: right;
  position: relative;
`
const DropdownChildren = styled.div`
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  min-width: 6rem;
  background: ${colors.white};
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 0 0 3px 3px;
  border: 1px solid ${colors.lightGrey};
  border-top: none;
  overflow: hidden;
  transform: translateX(-0.25rem);
`
const Dropdown = ({ active, title, children, onClick }) => (
  <div onClick={onClick}>
    <DropdownButton
      title={title}
      active={!!active}
      className={active && 'active'}
    />
    <DropdownChildren active={active}>{children}</DropdownChildren>
  </div>
)
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.lighterGrey};
`
const DropdownLink = styled(Link)`
  padding: 0.75rem;
  padding-left: 0.875rem;
  text-align: left;
  font-size: 0.875rem;
  color: ${colors.grey};
  text-decoration: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0px;
    left: 0;
    top: 0;
    background: ${colors.primary};
    transition: all 0.1s ease-out;
  }

  &:visited {
    color: ${colors.grey};
  }

  &:hover,
  &:focus {
    &:visited {
      color: ${colors.primary};
    }

    &:before {
      width: 6px;
    }
  }
`

const Header = () => {
  const outerRef = useRef(null)
  const [activeDropdown, setActiveDropdown] = useState(null)

  const setDropdown = title => {
    setActiveDropdown(activeDropdown !== title && title)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = event => {
    if (outerRef.current && !outerRef.current.contains(event.target)) {
      setActiveDropdown(null)
    }
  }

  return (
    <Container>
      <LogoContainer to="/">
        <Logo />
      </LogoContainer>

      <MenuContainer>
        <div ref={outerRef}>
          <Menu>
            <Dropdown
              title="Programs"
              onClick={() => setDropdown('programs')}
              active={activeDropdown === 'programs'}>
              <DropdownLink to="/future-capital-executive-program">
                Future Capital
              </DropdownLink>
              <DropdownLink to="/angel-academy/what-is-angel-academy">
                Angel Academy
              </DropdownLink>
            </Dropdown>

            <Dropdown
              title="About"
              onClick={() => setDropdown('about')}
              active={activeDropdown === 'about'}>
              <DropdownLink to="/about">Mission</DropdownLink>
              <DropdownLink to="/partners">Partners</DropdownLink>
              <DropdownLink to="/team">Team</DropdownLink>
              <DropdownLink to="/contact">Contact</DropdownLink>
            </Dropdown>

            <Dropdown
              title="More"
              onClick={() => setDropdown('more')}
              active={activeDropdown === 'more'}>
              <DropdownLink to="/blog">Blog</DropdownLink>
              <DropdownLink to="/resources">Resources</DropdownLink>
              <DropdownLink to="/women-in-venture">
                Women In Venture
              </DropdownLink>
            </Dropdown>
          </Menu>
        </div>
      </MenuContainer>
    </Container>
  )
}

export default Header
