import styled from 'styled-components'
import { vars } from '../components/theme'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => (props.small ? '660px' : '1000px')};
  padding: 0 8vw;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${vars.screenMedium}) {
    padding: 0 1.5rem;
  }
`
export default PageWrapper
