import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'

import { colors, globalCss, vars } from '../components/theme'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MetaHeader from '../components/MetaHeader'

const GlobalStyle = createGlobalStyle`
  ${globalCss}
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${vars.navHeight};
  margin-bottom: ${vars.footerHeight};
  overflow: hidden;

  @media screen and (min-width: ${vars.screenSmall}) {
    margin-bottom: ${vars.footerHeightWeb};
  }
`
const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  padding-bottom: 2rem;
`
const FooterWrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: ${vars.footerHeight};
  background: ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (min-width: ${vars.screenSmall}) {
    height: ${vars.footerHeightWeb};
  }
`

const Layout = ({ children }) => (
  <Fragment>
    <MetaHeader />
    <GlobalStyle />

    <BodyWrapper id="app-root">
      <Header />
      <Body>{children}</Body>
    </BodyWrapper>

    <FooterWrapper>
      <Footer />
    </FooterWrapper>
    <div id="modal-root" />
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
