import { css } from 'styled-components'
import { lighten, darken } from 'polished'
import 'ress'

const vars = {
  base: 20,
  navHeight: '3.25rem',
  footerHeight: '21rem',
  footerHeightWeb: '15rem',
  textShadow: '0.5px 0.5px 0 rgba(0, 0, 0, 0.25)',
  screenSmall: '600px',
  screenMedium: '900px',
  screenLarge: '1200px',
}

const fonts = {
  primary: `'Lato', 'Helvetica Neue', arial, sans-serif`,
  heading: `'futura-pt', 'Times New Roman', serif`,
}

const colors = {
  primary: '#50BFA8',
  secondary: '#45A6BA',
  primaryLight: lighten(0.39, '#50BFA8'),
  primaryDark: darken(0.18, '#50BFA8'),
  primaryBlack: darken(0.28, '#50BFA8'),
  secondaryLight: lighten(0.33, '#45A6BA'),
  secondaryDark: darken(0.18, '#45A6BA'),
  accent: '#EEB868',

  primaryText: '#383838',
  primaryTextLight: '#EEE',
  secondaryText: darken(0.31, '#45A6BA'),

  background: '#F9F7F6',
  backgroundDark: '#222',
  backgroundSecondary: '#F5F7FA',

  borderDark: '#222',
  borderLight: '#D8D8D8',
  black: '#111',
  darkerGrey: '#374047',
  darkGrey: '#ACB4B9',
  grey: '#656F76',
  lightGrey: '#CFD3D6',
  lighterGrey: '#F8F9F9',
  white: '#FFF',
  offWhite: '#F9F7F6',

  facebook: '#3B5998',
  linkedIn: '#0077B5',
  instagram: '#E44O5F',
  twitter: '#1DA1F2',
  youtube: '#F00',
}

const globalCss = css`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,400i,600');
  @import url('https://use.typekit.net/rva3mym.css');

  html {
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;

    @media (min-width: ${vars.screenMedium}) {
      font-size: 16px;
    }

    @media (min-width: ${vars.screenLarge}) {
      font-size: 20px;
    }

    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.01);
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background: ${colors.background};
    font-family: ${fonts.primary};
    color: ${colors.primaryText};
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    color: ${colors.black};
  }

  ::selection {
    background: ${colors.primary};
    color: black;
  }

  .todo,
  .debug {
    outline: 1px solid red;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1.125em 0 0.375em;
  }

  ol,
  ul {
    margin: 0 0 1em 2em;
  }
  li {
    line-height: 1.25;

    &:not(:last-of-type) {
      margin-bottom: 0.375em;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${fonts.heading};
    font-weight: 500;
    color: ${colors.secondaryText};
    line-height: 1.25;
  }

  h1 {
    font-size: 3rem;
    margin-top: 0;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.625rem;
  }
  h4 {
    font-size: 1.375rem;
  }
  h5 {
    font-family: ${fonts.primary};
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;
  }

  p {
    line-height: 1.5;
    margin-bottom: 1em;
  }

  hr {
    border: 2px solid ${colors.primary};
    border-radius: 2px;
    opacity: 0.5;
    width: 75%;
    min-width: 4rem;
    margin: 2rem auto;
  }

  a {
    transition: all 0.1s ease-out;
    color: ${colors.primaryDark};

    &:visited {
      color: ${colors.primaryDark};
    }
    &:hover,
    &:focus,
    &:active {
      color: ${colors.primary};
    }
  }

  button,
  .btn {
    border-radius: 3px;
    font-weight: 700;
    transition: all 0.125s ease-out;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background: ${colors.primaryDark};
    }
  }

  img {
    border-radius: 2px;
    width: auto;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    object-fit: contain;
  }

  section {
    margin: 1.5rem 0;
    width: 100%;
  }

  .subsection {
    margin: 2rem 0;
    width: 100%;
  }

  form {
    max-width: 20rem;
  }

  input {
    min-width: 6rem;
  }

  //
  // Utilities
  .mt-0 {
    margin-top: 0 !important;
  }
  .text-center {
    text-align: center;
  }

  //
  // Animations
  @keyframes fadeInDownSmall {
    from {
      transform: translate(0, -0.75rem);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes fadeInDown {
    from {
      transform: translate(0, -2rem);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`

export { globalCss, vars, colors, fonts }
