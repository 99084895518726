import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const MetaHeader = ({
  title,
  image,
  siteUrl,
  description,
  keywords,
  twitter,
  canonicalUrl,
  hidden,
}) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulPageHeader(
          id: { eq: "aa4d9492-8544-5317-a9fb-2070f78e1b60" }
        ) {
          image {
            file {
              url
            }
          }
          metaTitle
          metaDescription {
            metaDescription
          }
          metaKeywords {
            metaKeywords
          }
          metaSiteUrl
          metaTwitter
        }
      }
    `}
    render={data => {
      const {
        contentfulPageHeader: {
          metaTitle: defaultTitle,
          image: defaultImage,
          metaSiteUrl: defaultSiteUrl,
          metaDescription: defaultDescription,
          metaKeywords: defaultKeywords,
          metaTwitter: defaultTwitter,
        },
      } = data

      return (
        <Helmet>
          <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
          <meta
            name="description"
            content={description || defaultDescription.metaDescription}
          />
          <meta
            name="keywords"
            content={keywords || defaultKeywords.metaKeywords}
          />

          <meta property="og:url" content={siteUrl || defaultSiteUrl} />
          <meta
            property="og:title"
            content={title ? `${title} | ${defaultTitle}` : defaultTitle}
          />
          <meta
            property="og:description"
            content={description || defaultDescription.metaDescription}
          />
          <meta property="og:image" content={image || defaultImage} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={twitter || defaultTwitter} />
          <meta property="twitter:title" content={title || defaultTitle} />
          <meta
            name="twitter:description"
            content={description || defaultDescription.metaDescription}
          />
          <meta
            property="twitter:image"
            content={image ? `${defaultSiteUrl}${image}` : defaultImage}
          />

          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          {hidden && <meta name="robots" content="noindex, nofollow" />}
        </Helmet>
      )
    }}
  />
)

MetaHeader.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  siteUrl: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  twitter: PropTypes.string,
  canonicalUrl: PropTypes.string,
  hidden: PropTypes.bool,
}

export default MetaHeader
